import React, { Fragment, createRef } from "react";
import { Container, Button } from "reactstrap";
import { Link } from "gatsby";
import bg from "../assets/images/header.jpg";
import logo from "../assets/images/Psychedelics.svg";

function IndexHeader() {
  let pageHeader = createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  return (
    <Fragment>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: `url(${bg})`,
          }}
          ref={pageHeader}
        ></div>
        <div className="header-bg"></div>
        <Container>
          <div className="content-center brand d-flex flex-column h-100 justify-content-center">
            <div className="d-flex flex-column h-100 justify-content-center mt-auto slide-in-blurred-top">
              <h1 className="header-text mb-0 ">Thinking beyond the box.</h1>
              <h1 className="header-text">The future of <b >drugs</b>.</h1>
              <div className="d-flex flex-row align-items-center justify-content-center header-buttons">
                <Link to="/about" className="header-btn filled" >Learn more</Link>
                <Link to="/contact" className="header-btn outline">Contact us</Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

export default IndexHeader;
