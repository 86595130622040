import React, { useEffect, Fragment } from "react";
import { Container } from "reactstrap";
import IndexHeader from "../components/IndexHeader";
import SEO from "../components/SEO";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <Fragment>
      <SEO />
      <Helmet>
        <meta name="google-site-verification" content="zNU6bfCUNrnfEDBnN9mghxnu00pdbCDTAtPL5B5A8-o" />
      </Helmet>
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <Container></Container>
        </div>
      </div>
    </Fragment>
  );
};

export default IndexPage;
